<script setup lang="ts">
import './auth.css'
import { useForm, Link, InertiaForm } from '@inertiajs/vue3'
import {
  EnvelopeIcon,
  LockClosedIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline'

defineProps<{
  flash?: any
  canResetPassword: boolean
}>()

const { coords, resume } = useGeolocation()

const loginForm: InertiaForm<{
  email: string | null
  password: string | null
  remember: boolean
  coords: any
}> = useForm({
  email: null,
  password: null,
  remember: false,
  coords: null,
})

const submit = () => loginForm.post(route('login'))

onBeforeMount(() => {
  resume()
})

onMounted(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        loginForm.coords = {
          accuracy: position.coords.accuracy,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          altitude: position.coords.altitude,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          speed: position.coords.speed,
        }
      },
      function (error) {
        if (error.code === error.PERMISSION_DENIED) {
          console.warn('Usuário negou a solicitação de geolocalização.')
        }
      },
    )
  }
})

watch(
  coords,
  (value) => {
    loginForm.coords = {
      accuracy: value.accuracy,
      latitude: value.latitude,
      longitude: value.longitude,
      altitude: value.altitude,
      altitudeAccuracy: value.altitudeAccuracy,
      heading: value.heading,
      speed: value.speed,
    }
  },
  { immediate: true },
)
</script>

<template>
  <header class="header-wrapper">
    <AuthOrnament title="Acessar" />
    <span class="header-subtitle">
      Use suas credenciais para acessar sua conta
    </span>
  </header>

  <section class="tw-w-full">
    <Transition>
      <div
        v-show="loginForm.hasErrors || flash.error"
        role="alert"
        class="tw-daisy-alert tw-daisy-alert-error tw-mb-4"
      >
        <XCircleIcon class="tw-size-6 tw-shrink-0 tw-stroke-current" />
        <span>
          {{ loginForm.errors.email || flash.error }}
        </span>
      </div>
    </Transition>

    <form @submit.prevent="submit">
      <div class="tw-flex tw-flex-col tw-w-full tw-gap-3">
        <label
          class="tw-daisy-input tw-daisy-input-lg tw-daisy-input-bordered tw-flex tw-items-center tw-gap-2 tw-w-full"
        >
          <EnvelopeIcon
            class="tw-size-5 tw-opacity-70 tw-shrink-0 tw-stroke-current"
          />
          <input
            v-model="loginForm.email"
            type="email"
            class="tw-grow"
            placeholder="Digite o seu e-mail"
            required
          />
        </label>

        <label
          class="tw-daisy-input tw-daisy-input-lg tw-daisy-input-bordered tw-flex tw-items-center tw-gap-2 tw-w-full"
        >
          <LockClosedIcon
            class="tw-size-5 tw-opacity-70 tw-shrink-0 tw-stroke-current"
          />
          <input
            v-model="loginForm.password"
            type="password"
            class="tw-grow"
            placeholder="Digite a sua senha"
            required
          />
        </label>
      </div>

      <div class="tw-w-full tw-mt-4">
        <button
          type="submit"
          class="tw-daisy-btn tw-daisy-btn-block"
          :disabled="loginForm.processing"
        >
          <span
            v-if="loginForm.processing"
            class="tw-daisy-loading tw-daisy-loading-spinner tw-daisy-loading-md"
          ></span>

          <span v-else class="tw-uppercase">entrar</span>
        </button>
      </div>
      <span
        v-if="canResetPassword"
        class="tw-w-full tw-flex tw-justify-end tw-mt-3 tw-mb-3"
      >
        <Link
          :href="route('password.request')"
          class="tw-cursor-pointer tw-text-wc-blue-400"
        >
          Esqueci minha senha
        </Link>
      </span>
    </form>
  </section>
</template>
